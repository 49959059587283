<template>
    <div style="overflow-y: scroll; max-height: 89vh;margin: 10px 10px 0 10px; cursor: pointer" v-slimscroll="{height: '89vh'}">
        <template v-if="Object.keys(groupedAnnouncements).length > 0">
            <div v-for="date in Object.keys(groupedAnnouncements)" :key="date" style="width: 300px;" >
                <sub style="float: left;"><em>{{ formatDate(groupedAnnouncements[date][0].created_at) }}</em></sub>
                <br/>
                <ul style="list-style: none;background-color: #465660;border-radius: 2%">
                    <li v-for="announcement in groupedAnnouncements[date]" :key="announcement.id" :class="{'unread': announcement.read_at === null || announcement.read_at === undefined}" style="padding-left: 5px;padding-right:5px;border-radius: 2%">
                        <div style="clear: both; width: 100%;margin-top: 5px">
                            <div style="float:left; width: 5%" >
                                <font-awesome-icon v-if="announcement.read_at" :icon="['fa', 'envelope-open']"/>
                                <font-awesome-icon @click="setAnnouncementAsRead(announcement)" v-else :icon="['fa', 'envelope']"/>
                            </div>
                            <div style="margin-top: 0;float:right; width: 90%" @click="openAnnouncementModal(announcement)" >
                                <h4 style="margin-bottom: 0;margin-top: 0">{{ announcement.subject }}</h4>
                                <p style="margin-top: 5px;font-size: small"><em>{{ truncateText(announcement.description) }}</em></p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </template>
        <div v-else style="width: 300px;" >
            <ul style="list-style: none;background-color: #465660;border-radius: 2%">
                <li style="padding-left: 5px;padding-right:5px;border-radius: 2%">
                    <div style="clear: both; width: 100%;margin-top: 5px">
                        <div style="margin-top: 0;float:right; width: 90%" >
                            <h4 style="margin-bottom: 0;margin-top: 0">General</h4>
                            <p style="margin-top: 5px;font-size: small"><em>There are no announcements.</em></p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import AnnouncementInfo from "./AnnouncementInfo";
import {createNamespacedHelpers} from 'vuex';
import {formatAnnouncementDate} from "../../../utility/datetime";
const announcements = createNamespacedHelpers('announcements');

const MAX_CHARACTERS = 100;
export default {
    name: "AnnouncementMenu",
    props: {},
    computed: {
        ...announcements.mapState(['announcements']),
        groupedAnnouncements: function () {
            return _.groupBy(_.orderBy(this.announcements, (announcement) => new Date(announcement.created_at).getDate(), ['desc']), (announcement) => this.toDateString(new Date(announcement.created_at)));
        }
    },
    methods: {
        ...announcements.mapActions(['setAnnouncementAsReadById']),
        openAnnouncementModal: function (announcement) {
            this.setAnnouncementAsRead(announcement);
            if(announcement.description?.length >= MAX_CHARACTERS) {
                this.showModal(AnnouncementInfo, {announcement: announcement});
                this.$emit("close-announcements");
            }
        },
        toDateString(date) {
            return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        },
        formatDate: function (date) {
            return formatAnnouncementDate(date);
        },
        truncateText: function (text) {
            if(text.length > MAX_CHARACTERS) {
                return text.substr(0, MAX_CHARACTERS) + "...";
            } else {
                return text;
            }
        },
        setAnnouncementAsRead: function (announcement) {
            if(announcement.read_at === null || announcement.read_at === undefined) {
                this.setAnnouncementAsReadById(announcement.id);
            }
        }
    },
    mounted: function () {
        this.announcements
            .filter(announcement => announcement.description?.length <= MAX_CHARACTERS)
            .forEach(announcement => {
                this.setAnnouncementAsRead(announcement);
            })
    }
}
</script>

<style scoped lang="scss">
.dropdown-menu nav div div ul {
  padding: 0;
}

.dropdown-menu nav div div ul li {
  list-style: none;
  display: flex;
  transition: background-color 0.2s ease;
  text-align: left;
}

.dropdown-menu nav div div ul a.icon-link.isDefault svg {
  color: $color-20 !important;
}

.dropdown-menu nav div div ul li:hover {
  background-color: $color-03;
}

.dropdown-menu nav div div ul li:hover a.icon-link svg {
  color: $color-05;
}

.dropdown-menu nav div div ul li:hover a.icon-link svg:hover {
  color: $color-20;
}
.unread {
  color: #D5B957;
}
</style>
